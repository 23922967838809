import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from 'next/link'
import {
    getPersistedState,
    setPersistedState,
} from '../../actions/persistedStateActions';
import { PERSISTED_STATE_SEARCH_PAGE } from '../../__constants__';
import BreadcrumbSeparator from './BreadcrumbSeparator';

const propTypes = {
    value: PropTypes.string.isRequired,
    highlight: PropTypes.bool,
    url: PropTypes.string,
};

function Breadcrumb({ value, url, highlight }) {
    const onClick = () => {
        const persistedState = getPersistedState(PERSISTED_STATE_SEARCH_PAGE);

        if (!persistedState || !newPersistedState.searchType) {
            return;
        }

        const newPersistedState = { ...persistedState };
        newPersistedState.searchType = 'location';
        setPersistedState(PERSISTED_STATE_SEARCH_PAGE, newPersistedState);
    }

    const crumb = <span className={cn({ ['font-semibold']: highlight })}>
        {value}
    </span>

    if (!url) {
        return (
            <li className='no-underline text-primary-blue text-base'>
                {crumb}

                {!highlight && <BreadcrumbSeparator />}
            </li>
        );
    }

    return (
        <li>
            <Link href={url} onClick={onClick}>
                <a className='no-underline text-primary-blue text-base'>
                    {crumb}
                </a>
            </Link>

            {!highlight && <BreadcrumbSeparator />}
        </li>
    );
}

Breadcrumb.propTypes = propTypes;

export default Breadcrumb;
