import PropTypes from 'prop-types';
import cn from 'classnames';

const propTypes = {
    variant: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ])
};

const defaultProps = {
    variant: "default",
};

const BreadcrumbsBase = ({ children, variant }) => {
    return (
        <nav
            id='breadcrumbs'
            aria-label='breadcrumb'
            className={cn('pb-2 max-sm:pb-0 [&_a]:text-primary-blue',
                {
                    '!py-4 !px-6 bg-mint-green max-sm:!px-4': variant === 'blue'
                }
            )}
        >
            <ol className='m-0 flex p-0 flex-wrap list-none items-center [&_li]:py-1 [&_li]:px-2'>
                { children }
            </ol>
        </nav>
    );
};

BreadcrumbsBase.defaultProps = defaultProps;
BreadcrumbsBase.propTypes = propTypes;

export default BreadcrumbsBase;
